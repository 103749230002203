import logo from './area_proekt_fake_logo_messanger.png_no_bg.png';
import bgImg from './bg_img.png';

import './App.css';

function App() {
  return (
    <div className="App" style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        height:'100vh', 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <img src={logo} alt='logo' style={{ margin: 'auto', marginBottom: '0', height: '300px', width: '100%', objectFit: 'contain' }}/>
      <h1 style={{textShadow: '0 0 2px white', fontSize: '3rem', textAlign: 'center'}}> Очаквайте скоро ! </h1>
      {/* <img src={bgImg} alt='bg-img'></img> */}
    </div>
  );
}

export default App;
